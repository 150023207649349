





























































































































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import { RawLocation } from "vue-router";
import Enviroment from "@/models/enviroment";
import AppApiMixin from "../mixins/AppApi.vue";
import { validationMixin } from "vuelidate";

type SidebarItem = {
  mdi: string;
  text: string;
  to: RawLocation;
  use?: boolean;
};

type Divider = { isDivider: boolean; use?: boolean };

@Component({
  components: {
    AppApiMixin
  },
  computed: {
    ...mapState({
      appEnviroment: "appEnviroment",
      sidebar: "sidebar",
    }),
    ...mapGetters("contract", {
      useContract: "useContract",
      contractId: "id",
      contractType: "type",
    }),
    ...mapGetters("organization", { organizationId: "id" }),
    ...mapGetters("user", { passwordChangeRequired: "passwordChangeRequired" }),
    ...mapGetters("appeals", { unreadAppealsCount: "unreadAppealsCount" }),
    ...mapGetters("appeals", { listAppeals: "listAppeals" }),
    ...mapGetters("sidebar", { counterValue: "counterValue" }),
  },
  methods: {
    ...mapMutations({
      setIsMini: "sidebar/setIsMini"
    }),
    ...mapMutations("sidebar", { setCounterValue: "setCounterValue" }),
  },
  mixins: [validationMixin],
})
class Sidebar extends Vue {
  [x: string]: any;

  lastItem = {
    mdi: "mdi-help-circle",
    text: "Помощь",
    onClick: () => {
      window.open(
        "https://help.stack-it.ru/services/lk_ul/lk_ul_user/1_web_tk_ul_lk.html#%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B8-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D0%B8-%D0%B7%D0%B0%D1%87%D0%B5%D0%BC-%D0%BE%D0%BD-%D0%BD%D1%83%D0%B6%D0%B5%D0%BD",
        "_blank"
      );
    },
  };

  cv: number;

  public get appealsInfo() {
    return this.unreadAppealsCount ? Array.from(this.unreadAppealsCount).length : '0';
  }

  public get isMini(): boolean {
    return this.sidebar.isMini;
  }

  public get enviroment(): Enviroment | null {
    return this.appEnviroment.constants;
  }

  public get items(): (SidebarItem | Divider)[] {
    return [
      {
        mdi: "mdi-script-text",
        text: "Мои договоры",
        to: { name: "myContracts" },
      },
      {
        mdi: "mdi-office-building",
        text: "Карточка организации",
        to: {
          name: "companyCard",
          params: { companyId: this.organizationId },
        },
      },
      {
        mdi: "mdi-card-bulleted",
        text: "Карточка договора",
        to: {
          name: "contractCard",
          params: { contractId: this.contractId },
        },
      },
      { isDivider: true },
      {
        use: !!(this.enviroment && this.enviroment["ЛКЮЛОПЛАТА"]),
        mdi: "mdi-credit-card-outline",
        text: "Оплата",
        to: { name: "payments" },
      },
      {
        mdi: "mdi-counter",
        text: "Показания",
        to: { name: "countersValues" },
      },
      /*{
        mdi: "mdi-comment-text-multiple",
        text: "Обращения",
        to: {
          name: "appealsByDogovor",
          params: { contractId: this.contractId }
        },
      },*/
      {
        mdi: "mdi-comment-text-multiple",
        text: "Новое обращение",
        to: {
          name: "appealsAdd",
        },
      },
      {
        use:
          !!(this.enviroment && this.enviroment["ЛКЮЛПОЧАС"]) &&
          this.contractType === 3,
        mdi: "mdi-clock-time-nine",
        text: "Почасовое потребление",
        to: {
          name: "hourlyConsumption",
          params: { contractId: this.contractId }
        },
      },
      {
        mdi: "mdi-calendar-month",
        text: "Помесячный план",
        to: { name: "monthlyPlan" },
      },
      { isDivider: true },
      {
        mdi: "mdi-file-document",
        text: "Документы",
        to: {
          name: "documents",
          params: { contractId: this.contractId },
        },
      },
      {
        mdi: "mdi-file-chart",
        text: "Обороты и отчеты",
        to: {
          name: "turnoversAndReports",
          params: { companyId: this.organizationId },
        },
      },
      { isDivider: true },
    ];
  }

  public get showedItems(): (SidebarItem | Divider)[] {
    const { items, useContract, passwordChangeRequired } = this;

    if (passwordChangeRequired) return [];

    if (!useContract) return [items[0]];

    return items
      .map(({ use = true, ...item }) => ({ use, ...item }))
      .filter(({ use }) => use);
  }
}

export default Sidebar;
