import { checkbox, digit, email, phone, required } from "./field-rules";
import { filial_items } from "@/lib/config";

/**
 * * Внимание: не использовать тип number в качестве значения html-атрибута type
 * * поля ввода taxpayerId (поле ввода ИНН), поскольку это приводит к некорректной
 * * работе валидации.
 */

export default [
  {
    name: "filial",
    attrs: {
      type: "select",
      label: "Филиал",
      required: true,
      items: filial_items,
    },
    rules: [required]
  },
  {
    name: "taxpayerId",
    attrs: {
      type: "text",
      label: "ИНН",
      required: true
    },
    rules: [
      required,
      digit,
      {
        type: "minLength",
        arg: 10,
        error: "Значение поля должно содержать не менее 10 цифр"
      },
      {
        type: "maxLength",
        arg: 12,
        error: "Значение поля должно содержать не более 12 цифр"
      }
    ]
  },
  {
    name: "company",
    attrs: {
      type: "text",
      label: "Наименование организации",
      required: true
    },
    rules: [required]
  },
  {
    name: "email",
    attrs: {
      type: "email",
      label: "Email",
      required: true
    },
    rules: [required, email]
  },
  {
    name: "phone",
    attrs: {
      type: "tel",
      label: "Мобильный телефон",
      required: true,
      prefix: "+7"
    },
    rules: [required, phone]
  },
  {
    name: "contractList",
    attrs: {
      type: "textarea",
      label: "Подключаемые договоры",
      required: true
    },
    rules: [required]
  },
  {
    name: "allowDataHandling",
    attrs: {
      type: "checkbox",
      label: "Я даю согласие на обработку персональных данных",
      required: true
    },
    rules: [checkbox]
  }
];
