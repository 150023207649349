const filial_items: string[] = [
  "Филиал ПАО \"Россети Северный Кавказ\" - \"Дагэнерго\"",
  "Филиал ПАО \"Россети Северный Кавказ\" - \"Карачаево-Черкесскэнерго\"",
  "Филиал ПАО \"Россети Северный Кавказ\" - \"Каббалкэнерго\"",
  "Филиал ПАО \"Россети Северный Кавказ\" - \"Ингушэнерго\"",
  "Филиал ПАО \"Россети Северный Кавказ\" - \"Севкавказэнерго\"",
];

export {
  filial_items
};
